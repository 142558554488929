<template>
  <div class="common-aside">
    <div class="logo">
      <img src="@/assets/logo.png">
      <h1 class="logo-text">鼎推中台</h1>
    </div>
    <el-menu
      :router="true"
      :default-active="routerpath"
      class="el-menu-vertical-demo"
      @select="nodeItem"
      @open="handleOpen"
      @close="handleClose"
      unique-opened
      :collapse="$store.state.breadcrumb.navCollapse">
      <!-- 一级目录 -->
      <div v-for="nav in menulist" :key="nav.id">
        <el-menu-item v-if="nav.hidden == 0"  :index="path(nav.path,nav.children[0].path)">
          <i :class="['iconfont', nav.meta.icon]"></i>
          <span slot="title" style="margin-left:10px;">{{nav.meta.title}}</span>
        </el-menu-item>
        <el-submenu v-else :index="nav.path">
        <template slot="title">
          <i :class="['iconfont', nav.meta.icon]"></i>
          <span style="padding-left:10px;">{{ nav.meta.title }}</span>
        </template>
        <!-- 二级菜单 -->
        <div v-for="subNav in nav.children" :key="subNav.id">
          <el-menu-item v-if="subNav.hidden !== 0" :index="path(nav.path,subNav.path)">
          {{ subNav.meta.title }}
        </el-menu-item>
        </div>
      </el-submenu>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navPath: [],
      activeMenu: '',
      menulist:[]
    }
  },
  computed:{
    routerpath() {
      if (!this.$route.meta.showPath) {
        // console.log(this.$route.path, 'this.$route.path');
          return this.$route.path
      } else {
        // console.log(this.$route.meta.showPath, 'this.$route.meta.showPath');
          return this.$route.meta.showPath
      }
    },
    path(){
      return function(e,child){
        if(e == '/') return e + child;
        else return e + '/' + child
      }
    }
  },
  mounted(){
    // console.log('menulist',)
    this.menulist = this.$store.state.user.menulist
  },
  methods: {
    nodeItem (index) {
      this.activeMenu = index
    },
    handleOpen(key, keyPath) {
        // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    }
  },
}
</script>

<style lang="scss" scoped>
.common-aside {
  .logo {
    width: 100%;
    height: 60px;
    // background-color: ;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo-text{
      color: #333;
      font-size: 22px;
      font-weight: bold;
      margin-left: 10px;
    }
    img {
      // width: 70%;
      height: 48%;
    }
  }
  .el-menu-item.is-active{
    background-color: #409EFF !important;
    color: #fff;
  }
}
</style>
