import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import '@/router/promission'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@wangeditor/editor/dist/css/style.css' //富文本样式
import VueParticles from 'vue-particles' // 登录粒子背景特效
import Util from './utils/util.js'
import ('@/assets/icons/iconfont.css')

Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'medium', zIndex: 3000 });
Vue.use(VueParticles);
Vue.use(VueRouter)

Vue.prototype.$util = Util;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
