import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    // meta: {
    //   roles: '*'
    // },
    component: () => import('_views/Login')
  },
  // {
  //   path: '*',
  //   name: '404',
  //   component: () => import('_views/NotFound')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



// router.beforeEach((to, from, next) => {
//   if (to.path !== "/login") {
//     // 鉴权
//     if (isLogin()) {
//       // 登录
//       next();
//     } else {
//       // 未登录
//       next("/login");
//     }
//   } else {
//     next();
//   }
// });

/**
 * 重写路由的push方法
 * 解决，相同路由跳转时，报错
 * 添加，相同路由跳转时，触发watch (针对el-menu，仅限string方式传参，形如"view?id=5")
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
return originalPush.call(this, location).catch(err => err)
}
export default router
