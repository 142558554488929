export default {
  namespaced: true,
  state: {
    // 判断state有没有数据
    userInfo: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {},
    menulist:sessionStorage.getItem('menuList') ? JSON.parse(sessionStorage.getItem('menuList')) : [],
    token: sessionStorage.getItem('token') ?  sessionStorage.getItem('token') : ''
  },
  mutations: {
    // 登录初始化
    INIT_USER (state, userInfo) {
      state.userInfo = userInfo
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    // 存整理好的路由组件
    SET_MENU(state,data){
      state.menulist = data;
      sessionStorage.setItem('menuList',JSON.stringify(data))
    },
    // 设置token
    SET_TOKEN (state,data) {
      state.token = data
      sessionStorage.setItem('token', data);
    },
    // 退出登录
    OUT_LOGIN () {
      sessionStorage.removeItem('userInfo')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('menuList')
    }
  }

}
