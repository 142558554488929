import {
	ElMessageBox,
	ElMessage
} from 'element-ui'
import moment from "moment";
/**
 * 消息提示
 * @param msg 提示信息
 * @param type 消息类型
 */
const message = (msg, type) => {
	ElMessage({
		showClose: true,
		message: msg,
		type: type
	});
}
/**
 * 成功提示
 * @param msg 提示信息
 */
const success = (msg) => {
	message(msg, 'success');
}
/**
 * 消息提示
 * @param msg 提示信息
 */
const info = (msg) => {
	message(msg, 'info');
}
/**
 * 错误提示
 * @param msg 提示信息
 */
const error = (msg) => {
	message(msg, 'error');
}

/**
 * 警告提示
 * @param msg 提示信息
 */
const warning = (msg) => {
	message(msg, 'warning');
}

/**
 * alert提示框
 * @param title 标题
 * @param msg 信息
 * @param ok ok函数
 * @param okText ok按钮文字
 */
const alert = ({
	title,
	msg,
	ok,
	okText
}) => {
	if (!title) {
		title = '提示';
	}
	if (!msg) {
		msg = '错误';
	}
	if (!okText) {
		okText = '确定';
	}
	ElMessageBox.alert(msg, title, {
		confirmButtonText: okText,
	}).then(ok ? ok : () => { });
}

/**
 * confirm 提示框
 * @param title 标题
 * @param msg 信息
 * @param ok ok函数
 * @param okText ok按钮文字
 * @param cancel 取消函数
 * @param cText cancel按钮文字
 */
const confirm = ({
	title,
	msg,
	ok,
	okText,
	cancel,
	cText
}) => {
	var mtitle = '提示';
	var mOK = '确定'
	var mc = '取消'
	ElMessageBox.confirm(msg, title ? title : mtitle, {
		confirmButtonText: okText ? okText : mOK,
		cancelButtonText: cText ? cText : mc,
	}).then(ok ? ok : () => { }).catch(cancel ? cancel : () => { });
}

// process.env.NODE_ENV 是node环境变量, 我们这里判断的是 如果环境是生产环境? '生产源' : '开发源'
const configUrl = process.env.NODE_ENV === "production" ? "https://dt.dpzyun.top/" : 'https://dt.dpzyun.top/';
// 时间戳转日期
const date = (timestampa) => {
	var date = new Date(timestampa.length > 10 ? timestampa : timestampa * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	var Y = date.getFullYear();
	var M = (date.getMonth() + 1);
	M < 10 ? M = '0' + M : M;
	var D = date.getDate();
	D < 10 ? D = '0' + D : D;
	var h = date.getHours();
	h < 10 ? h = '0' + h : h;
	var m = date.getMinutes();
	m < 10 ? m = '0' + m : m;
	var s = date.getSeconds();
	s < 10 ? s = '0' + s : s;
	return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
}
// 表格头部样式
const hCellStyle = {
	'text-align': 'center',
	'background-color': '#fafafa',
	'color': '#333',
	'font-weight': 'bold',
}
// 表格单元格样式
const cellStyle = {
	'text-align': 'center',
	'height': '60px'
}

// 日期转时间戳
function timetamp(time) {
	var date = new Date(moment(time).format("YYYY/MM/DD HH:mm:ss"))
	date.setFullYear(time.substring(0, 4)); //年
	date.setMonth(time.substring(5, 7) - 1); //月
	date.setDate(time.substring(8, 10)); //日
	date.setHours(time.substring(11, 13)); //时
	date.setMinutes(time.substring(14, 16)); //分
	date.setSeconds(time.substring(17, 19)); //秒
	return Date.parse(date) / 1000;
}

// 根据时间获取0点时间戳
function startDate(time) {
	var date = new Date(moment(time).format("YYYY/MM/DD HH:mm:ss"))
	//console.log('datetime',date)
	// time = new Date(time); // date 类型数据转成 时间字符串
	// time = time.getTime();
	let time1 = date.setHours(0, 0, 0, 0) / 1000
	return time1;
}
// 根据时间获取23:59:59时间戳
function endDate(time) {
	var today = new Date(moment(time).format("YYYY/MM/DD HH:mm:ss"))
	// let today = new Date(time)
	let time2 = today.setHours(23, 59, 59, 0) / 1000
	return time2
}

function paramsValidate(params) {
	let flag = true;
	for (var key in params) {
		if (params[key] == '' || !params[key]) {
			return false;
		}
	}
	return flag;
}
function unionArray(arr1, arr2) {
	const newSet = new Set(arr1)
	arr2.map(item => {
		newSet.add(item)
	})
	return [...newSet].sort()
}
// 验证手机号
const cheackMobile = (rule, value, callback) => {
	const regMobile =
		/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
	if (!regMobile.test(value)) {
		callback(new Error("请输入格式正确的手机号!"));
	}
	callback();
};

// 验证金额 保持两位小数
const verifyMoney = (rule, value, callback) => {
	const regMobile =
		/(^([0-9]{1,18})?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
	if (!regMobile.test(value)) {
		callback(new Error("小数点以后最多保留两位!"));
	}
	callback();
};

// 大于0的正整数
const verifyInteger = (rule, value, callback) => {
	const regMobile = /^\+?[1-9]\d*$/;
	if (!regMobile.test(value)) {
		callback(new Error("必须是大于0的正整数!"));
	}
	callback();
};

// 0.1到9.9之间的数字
const discountVerify = (rule, value, callback) => {
	const regMobile = /^(?=0\.[1-9]|[1-9]\.\d).{3}|^([1-9])$/;
	if (!regMobile.test(value)) {
		callback(new Error("请输入0.1到9.9之间的数字!"));
	}
	callback();
};

// 验证上传文件
const fileVerify = (rule, value, callback) => {
	console.log(value, 'value');
	console.log(rule, 'rule');
	// if (this.showFlag && this.file == null) {
	// 	// 未上传文件
	// 	callback("请上传文件");
	// }
	// callback();

};
export default {
	success,
	info,
	error,
	warning,
	alert,
	confirm,
	configUrl,
	date,
	timetamp,
	hCellStyle,
	cellStyle,
	startDate,
	endDate,
	paramsValidate,
	unionArray,
	cheackMobile,
	verifyMoney,
	verifyInteger,
	discountVerify,
	fileVerify
}
