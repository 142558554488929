// const lastingTab = (newNavItems) => {
//   sessionStorage.setItem('navPaths', JSON.stringify(newNavItems))
// }
export default {
  // 命名空间
  namespaced: true,
  state: {
     // 侧边导航栏折叠状态
    navCollapse: false,
    // 面包屑路径
    // navPath: sessionStorage.getItem('navPaths')
    // ? JSON.parse(sessionStorage.getItem('navPaths'))
    // : []
  },
  mutations: {
     //  切换侧边导航折叠状态
     TOGGLE_MENU (state) {
      state.navCollapse = !state.navCollapse
    },
    // ADD_NAV_TAB (state,navItemPath) {
    //     // 1.判断navItemPath是否已存在上一个了，不能重复
    //   // 2.判断是不是首页，如果是首页不能添加 首页是固定的
    //   if (state.navPath.find(item => item.path === navItemPath.path)) {
    //     return false
    //   }
    //   const noAddPath = ['/platform_management', '/login']
    //   if (noAddPath.includes(navItemPath.path)) {
    //      return false 
    //   }
    //   // 存到这个数组里面去
    //   state.navPath.push(navItemPath)
    //   // 持久化
    //   lastingTab(state.navPath)
    // }
  }
}