import axios from 'axios'
import { Loading, Message, Notification } from 'element-ui'
import router from '@/router'
import { getToken } from '_utils/user'
import qs from 'qs'
import store from '@/store/index'
const request = axios.create({
    // 定义源
    // VUE_APP_BASEURL是.env.development文件下的
    baseURL: process.env.VUE_APP_BASEURL_API,
    // 设置了在6000毫秒内请求数据 如果没有请求成功就执行错误函数
    // timeout: 6000
  })
  let loading
// loading开始 方法
function startLoading () {
  // element-ui loading 服务调用方式
  loading = Loading.service({
    lock: true,
    text: '拼命加载中...',
    spinner: 'el-icon-loading', // 自定义图标
    background: 'rgba(0, 0, 0, 0.7)'
  })
}
  // loading结束 方法
  function endLoading () {
    loading.close()
  }

// 请求拦截器
request.interceptors.request.use(function (config) {
  // console.log(config, '请求拦截')
  if (config.method === 'post' && config.url === '/login') {
    // 这个是转格式的代码
    config.data = qs.stringify(config.data)
  }
  config.baseURL = 'https://dt.dpzyun.top'
    // 请求显示loading 效果
    startLoading()
    // 获取缓存token
    const Token = getToken()
    if (Token) {
      // 请求头携带Token ，用于后端token验证
      config.headers.token = Token
    }
    // 配置表单格式发送
    if (config.data && config.data.contentType === 'form') {
      delete config.data.contentType
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      let ret = ''
      for (const it in config.data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(config.data[it]) + '&'
      }
      config.data = ret.slice(0, -1)
    } else {
      // 处理params参数
      if (config.params) {
        const url = config.url + '?' + qs.stringify(config.params, { indices: false })
        config.params = {}
        config.url = url
      }
    }
    return config
  }, function (error) {
    endLoading()
    return Promise.reject(error, 'error')
  })

  // 响应拦截器
  request.interceptors.response.use(function (res) {
    // 关闭loading效果
    endLoading()
    // 判断code码做出对应的操作
    if (res.data.code === -1) {
      store.commit('user/OUT_LOGIN')
      Notification({
        title: '提示',
        type: 'warning',
        message: 'token状态过期，请重新登录',
        duration: 0,
        onClose: () => {
          store.commit('user/OUT_LOGIN')
          router.replace('/login')
        }
      })
    } 
    else if (res.data.code !== 0) {
      Message({
        type: 'error',
        message: res.data.message
      })
    }
    // else if (res.data.code === 403) {
    //   store.commit('user/OUT_LOGIN')
    //   Message({
    //     type: 'error',
    //     message: '用户未登录，请登录',
    //     onClose: () => router.replace('/login')
    //   })
    // }
  
    return res.data
  }, function (error) {
    Message.error({
      message: error
    })
    // 关闭loading效果
    endLoading()
    return Promise.reject(error)
  })
  
  export default request