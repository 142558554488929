import request from "@/utils/request";

const prefix = '/center_api'


/**
 * 登陆
 */
export function userLogin (params = {}) {
  params.contentType = 'form'
  return request({
    url: `${prefix}/system/user/login`,
    method: 'post',
    data: params
  })
}

/**
 * 退出登陆
 */
export function loginOut (params = {}) {
  params.contentType = 'form'
  return request({
    url: `${prefix}/system/user/login_out`,
    method: 'post',
    data: params
  })
}

/**
 * 获取登录用户的信息数据
 */
export function userInfo (params = {}) {
  params.contentType = 'form'
  return request({
    url: `${prefix}/system/user/login_user`,
    method: 'post',
    data: params
  })
}

/**
 * 修改密码
 */
export function changePasswd (params = {}) {
  params.contentType = 'form'
  return request({
    url: `${prefix}/system/user/change_passwd`,
    method: 'post',
    data: params
  })
}
