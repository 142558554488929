<template>
  <div class="common-head">
   <div class="head-fl">
    <!-- <i
      @click="toggleMenu"
      :class="$store.state.breadcrumb.navCollapse?'el-icon-s-unfold': 'el-icon-s-fold'"
    ></i> -->
    <el-breadcrumb separator="/" style="margin-left:20px;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item  v-if="$route.path !== '/platform_management'" >{{ $route.meta.title }}</el-breadcrumb-item>
    </el-breadcrumb>
   </div>
   <div class="head-fr">
    <el-dropdown @command="clickDropdownMenu">
      <div class="user-info">
        <el-avatar :size="35" :src="$store.state.user.userInfo.avatar"></el-avatar>
        <span>{{ $store.state.user.userInfo.nick_name }}</span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item icon="el-icon-s-custom" command="userCore">账号设置</el-dropdown-item>
        <el-dropdown-item icon="el-icon-s-release" command="loginOut">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
   </div>
    <!-- 账号设置对话框 -->
    <el-dialog :visible.sync="dialogVisible" title="修改密码">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="原密码" prop="oldPwd" :label-width="formLabelWidth" >
          <el-input v-model="form.oldPwd" type="text" show-password/>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd" :label-width="formLabelWidth">
          <el-input v-model="form.newPwd" type="text" show-password/>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmPwd" :label-width="formLabelWidth">
          <el-input v-model="form.confirmPwd" type="text" show-password/>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="changePassword">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { loginOut, changePasswd } from '@/api/login/login'
export default {
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.newPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        oldPwd: '',
        newPwd: '',
        confirmPwd: '',
      },
      // 表单校验
      rules: {
        oldPwd: [
          { required: true, message: "旧密码不能为空", trigger: "blur" },
        ],
        newPwd: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          { min: 6, max: 16, message: "长度在 6 到 16个字符", trigger: "blur" }
        ],
        confirmPwd: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { required: true, validator: equalToPassword, trigger: "blur" }
        ]
      },
      dialogVisible: false,
      formLabelWidth: '140px'
    }
  },
 methods: {
  toggleMenu () {
    this.$store.commit('breadcrumb/TOGGLE_MENU')
  },
   // 点击右边头像
   clickDropdownMenu (action) {
      //  action 就是绑定的command的值 来确定你点击的是哪个
      if (action === 'loginOut') {
        loginOut().then(res => {
          console.log(res);
          if (res.code === 0) {
        // 清除 缓存，自动触发路由前置守卫，判断没有tonken，会自动跳转登录页
            this.$store.commit('user/OUT_LOGIN')
            this.$message({
              type: 'success',
              duration: 1000,
              message: '退出登录成功',
              onClose: () => this.$router.go(0)
            })
          }
        })
      }
      if (action === 'userCore') {
        this.dialogVisible = true
      }
    },
    // 账号设置
    changePassword () {
      const params = {
        oldPwd: this.form.oldPwd,
        newPwd: this.form.newPwd,
        confirmPwd: this.form.confirmPwd,
      }
      changePasswd(params).then(res => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '修改成功！',
            onClose: () => this.dialogVisible = false
          })
        }
      })
    },
 }
}
</script>

<style lang="scss" scoped>
.common-head{
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  background-color:#fff ;
  height: 100%;
  box-shadow: 2px 3px 8px #999;
  .head-fl{
    display: flex;
    align-items: center;
  }
  .head-fr{
    display: flex;
    align-items: center;
    .user-info{
      display: flex;
      align-items: center;
      span{
        margin: 0 7px;
      }
    }
  }
}
</style>
