<template>
    <div class="app">
        <el-container>
          <el-aside :width="$store.state.breadcrumb.navCollapse?'auto':'200px'">
            <CommonAside/>
          </el-aside>
            <el-container>
                <el-header>
              <CommonHeader/>
              </el-header>
                <el-main>
                  <router-view class="main"></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import CommonHeader from './components/CommonHeader.vue'
import CommonAside from './components/CommonAside.vue';
    export default {
        name: 'adminPage',
        components: {
            CommonHeader,
            CommonAside
        }
    }
</script>

<style lang="scss" scoped>
.app{
    background-color: #f5f5f5;
  .el-container {
    height: 100vh;
    background-color: #f5f5f5;
    .el-aside {
      height: 100vh;
      background-color: #fff;
    }
    .el-header {
      height: 60px;
      padding: unset;
    }
    .el-main {
      box-sizing: border-box;
      padding: 20px;
      // background-color: red;

      .main{
        height: calc(100vh - 100px); 
        background: #fff;
        box-sizing: border-box;
        padding: 20px;
        overflow-y: auto;
        border-radius:14px ;
      }
    }
  }
}
</style>