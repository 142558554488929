import router from './index.js'
import LayOut from '@/components/LayOut'
import store from '@/store'
// 获取后端返回的菜单
// 封装component
function _import(path) { //对路由的component解析
	return (resolve) => require([`@/views/${path}`], resolve);
}
// 处理后端返回的菜单
var menuList // 后端返回的路由数组（不是我要的格式）
var routers // 整理后的的路由数组
router.beforeEach((to, from, next) => {
router.getRoutes()
menuList = store.state.user.userInfo.auth;

	if (sessionStorage.getItem('token')) {
			if (!routers) { // 不加这个判断，路由会陷入死循环
        if(!getMenu()){
				// 将路由第一项path改为'/',便于登录时直接进入
				// console.log(menuList, 'menuList');
          menuList[0].web_route = '/'
          // routers = filterAsyncRouter(menuList)
          routers = initObj(menuList)
          // 将路由保存至本地及store中
          store.commit('user/SET_MENU', routers)
          // 将数组添加至路由
          routerGo(to, next)
        } else {
          routers = getMenu()
          routerGo(to, next)
        }
      } else {
        next()
      }
	} else {
		if (to.path === '/login') {
			next()
		} else {
			next('/login')
		}
	}
})
// 获取路由菜单
function getMenu(){
  return JSON.parse(sessionStorage.getItem('menuList'))
}
// 初始化数组，页面内使用
function initObj(asyncRouterMap){
	// console.log(asyncRouterMap, 'asyncRouterMap');
  let arr = asyncRouterMap.map(e => {
    // 是否是菜单 e.is_menu是菜单  else 是按钮
		if (e.is_menu) {
			// 路由的格式
			var obj = {
				id: e.id,
				pid: e.pid,
				path: e.web_route,
				component: e.component,
				meta: {
					title: e.auth_title,
					icon: e.icon,
					showPath: e.show_path,
					relevance_id: e.relevance_id
				},
				hidden: e.is_show,
				children: e.children && e.children.length && e.children[0].is_menu ? initObj(e.children) : [],
        is_menu:e.is_menu
			}
			// if (e.children && e.children.length && !e.children[0].is_menu) {
			// 	obj.meta.roles = [];
			// 	obj.meta.roles = initObj(e.children)
			// }
		} else {
			var obj = {
				id: e.id,
				pid: e.pid,
				path: e.web_route,
				title: e.auth_title,
				relevance_id: e.relevance_id,
        is_menu:e.is_menu
			}
		}
		return obj
	})
	return arr
}
// 初始化路由，仅作用于addRoute，遍历后台传来的路由字符串，转换为组件对象。 就是整理后端的数据，把他的数组转成路由对象
function filterAsyncRouter(asyncRouterMap) {
	// console.log(asyncRouterMap);
	let arr = asyncRouterMap.map(e => {
    // 是否是菜单 e.is_menu是菜单 只有菜单才加进路由里面
		if (e.is_menu) {
			// 路由的格式
			var obj = {
				path: e.path,
				component: e.component == 'Layout' ? LayOut : _import(e.component),
				meta: e.meta,
				hidden: e.hidden,
				children: e.children.length && e.children[0].is_menu ? filterAsyncRouter(e.children) : [],
			}
      // 如果是目录第一层直接重定向到子菜单的第一个
      if(e.children.length){
        if (e.path == '/') var redirect = '/' + e.children[0].path;
			  else var redirect = e.path + '/' + e.children[0].path;
        if (e.children[0].is_menu) obj.redirect = redirect;
      }
		}
		return obj
	})
	console.log(arr);
	return arr
}
function routerGo(to, next) {
	//过滤路由
  routers = filterAsyncRouter(routers)
	routers.forEach((route) => {
		router.addRoute(route);
	});
	// router.addRoutes(getRouter) //动态添加路由
	global.antRouter = routers //将路由数据传递给全局变量，做侧边栏菜单渲染工作
	router.addRoute({
		path: '/:pathMatch(.*)',
		component: () => import('../views/NotFound/index.vue'),
		meta: {
			hidden: true
		}
	})
	next({
		...to,
		replace: true
	})
}



